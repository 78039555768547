import {withActions} from 'carmi-host-extensions'
export const name = 'PageTransitionAspect'

export const defaultModel = {
    hiddenPages: {}
}

const setElementStyle = (document, elementRef, attribute, value) => {
    const element = document.getElementById(elementRef)
    if (element) {
        element.style[attribute] = value
    }
}

const setPageOverflow = (document, pageRef, displayValue) => {
    setElementStyle(document, pageRef, 'overflow', displayValue)
}

const setPageMaxHeight = (document, pageRef, displayValue) => {
    setElementStyle(document, pageRef, 'maxHeight', displayValue)
}

const setPageVisibility = (document, pageRef, visibilityValue) => {
    setElementStyle(document, pageRef, 'visibility', visibilityValue)
}

const showPage = (document, page) => {
    setPageMaxHeight(document, page, '')
    setPageOverflow(document, page, '')
    setPageVisibility(document, page, 'visible')
}

const hidePage = (document, oldPage) => {
    setPageVisibility(document, oldPage, 'hidden')
    setPageMaxHeight(document, oldPage, '0px')
    setPageOverflow(document, oldPage, 'hidden')
}

const prepareTransition = (document, oldPage, newPage) => {
    showPage(document, newPage)

    // TODO BOLT-515 Once page transition (out) is implemented this should be revisited
    setPageMaxHeight(document, oldPage, '0px')
}

const createPageTransition = ({pageTransition, animator, duration, document, reportTransitionEnded, getCurrentNavigationPageIds, setHiddenPage, $runInBatch}) => {
    const {callbacks = {}, previousRef, currentRef, delay, params, transitionName} = pageTransition

    const oldPageElement = document.getElementById(previousRef)
    const newPageElement = document.getElementById(currentRef)
    if (!oldPageElement || !newPageElement) {
        return
    }

    prepareTransition(document, previousRef, currentRef)

    callbacks.onComplete = () => {
        const navPageIds = getCurrentNavigationPageIds()
        hidePage(document, navPageIds.previousRef)
        showPage(document, navPageIds.currentRef)

        $runInBatch(() => {
            setHiddenPage(navPageIds.previousRef, true)
            setHiddenPage(navPageIds.currentRef, false)
        })

        reportTransitionEnded()
    }

    animator.sequence({callbacks})
        .add(animator.transition(transitionName, oldPageElement, newPageElement, duration, delay, {...params}))
        .add(animator.animate('BaseClear', [oldPageElement, newPageElement], 0, 0, {
            props: 'opacity,x,y',
            immediateRender: false
        }))
}

const createBackgroundTransition = (bgTransition, animator, duration, transitionName, params, document) => {
    if (bgTransition) {
        const {delay, callbacks} = bgTransition
        const oldBgElement = document.querySelector('[id^="SITE_BACKGROUND_previous"]')
        const newBgElement = document.querySelector('[id^="SITE_BACKGROUND_current"]')
        animator.sequence({callbacks})
            .add(animator.transition(transitionName, oldBgElement, newBgElement, duration, delay, params))
            .add(animator.animate('BaseClear', [oldBgElement, newBgElement], 0, 0, {
                props: 'opacity,x,y',
                immediateRender: false
            }))
    }
}

export const functionLibrary = {
    hidePageIfNeeded: (document, hiddenPages, id) => {
        if (hiddenPages[id]) {
            hidePage(document, id)
        }
    },
    registerBackgroundTransition: withActions(({setPageTransitionParams}, comp, previousRef, currentRef, callbacks) => {
        setPageTransitionParams({
            comp,
            previousRef,
            currentRef,
            callbacks
        })
    }),

    registerPageTransition: withActions(({setStartTransition}, isDuringPageTransition) => {
        setStartTransition(isDuringPageTransition)
    }),

    handlePageTransition: withActions(({$runInBatch, setStartTransition, setPageTransitionParams}, shouldStartTransition, getTransitionParams, reportPageNavigationInterationEnded, reportPageNavigationDone) => {
        if (shouldStartTransition) {
            reportPageNavigationInterationEnded()
            reportPageNavigationDone()

            const {
                windowObject,
                duration,
                pageTransition,
                bgTransition,
                reportTransitionEnded,
                animator,
                scrollToAnchor,
                isRouteChangeInSamePage,
                getCurrentNavigationPageIds,
                setHiddenPage
            } = getTransitionParams()
            const {params, transitionName} = pageTransition
            const {document} = windowObject

            if (isRouteChangeInSamePage) {
                scrollToAnchor(0)
                setStartTransition(false)
                reportTransitionEnded()
            } else {
                createPageTransition({
                    $runInBatch,
                    pageTransition,
                    animator,
                    duration,
                    document,
                    reportTransitionEnded,
                    getCurrentNavigationPageIds,
                    setHiddenPage
                })
                createBackgroundTransition(bgTransition, animator, duration, transitionName, params, document)
                scrollToAnchor(duration)
                setPageTransitionParams()
                setStartTransition(false)
            }
        }
    })
}
